import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`notify discord_webhook:
  - name: discord_webhook
    platform: rest
    method: POST
    message_param_name: content
    title_param_name: username
    data:
      username: Home Assistant
    resource: 'https://discordapp.com/api/webhooks/36723429/UBbENaEKpuUHOWs3wBjAiq2Ribcf74688fcn6n8748M9y0p7KD7C-YJ'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      